import styled from 'styled-components';
import { flexbox, space, typography, color, border, layout } from 'styled-system';

// fixed for issue with forwarded props like flexDirection, etc:  https://github.com/styled-system/styled-system/pull/1729
import shouldForwardProp from '@styled-system/should-forward-prop';

const Container = styled.div.withConfig({ shouldForwardProp })`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* Overwrite the defaults from above */
  ${typography}
  ${flexbox}
  ${space}
  ${color}
  ${border}
  ${layout}
  ${({ gap }) => gap && `gap: ${gap}`}
`;

export default Container;
